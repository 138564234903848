<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                Scientific Production Report
                <!-- <strong class="badge bg-light-secondary scientific-year">
                  <template
                    v-if="loading"
                  >
                    <i
                      data-feather="loader"
                      class="loop-loading"
                      :class="{'d-none': loading}"
                    />
                  </template>
                  <template
                    v-else
                  >
                    {{ currentYear }}
                  </template>
                </strong> -->
                <strong class="badge bg-light-secondary scientific-year">{{ currentYear }}</strong>
                Publications, Other Research Activities and Funding.
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <!-- <a href="pdf-scientific-production.html" target="_blank" class="btn btn-icon btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Download in pdf"><i data-feather='download-cloud'></i> Donwload</a> -->

            <!-- <div class="btn-group">
              <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0 btn btn-flat-dark dropdown-toggle waves-effect"
              >
                <template #button-content>
                  <button
                    id="dropdownMenuButton"
                    class="btn btn-primary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >Actions <i data-feather="chevron-down" />
                  </button>
                </template>
                <b-dropdown-item :to="{ name: 'apps-todo' }">
                  <h6 class="dropdown-header">
                    Download
                  </h6>
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'apps-todo' }">
                  <span class="dropdown-item">PDF</span>
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'apps-todo' }">
                  <span class="dropdown-item">Word</span>
                </b-dropdown-item>
                <a
                  class="dropdown-item"
                  href="#"
                >Word</a>
                <a
                  class="dropdown-item"
                  href="#"
                >Excel</a>
                <div class="dropdown-divider" />
                <h6 class="dropdown-header">
                  Admin
                </h6>
                <a
                  class="dropdown-item"
                  href="researcher-scientific-production-stats.php"
                >General view</a>
              </b-dropdown>
            </div> -->

            <span
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Open filters"
            ><a
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasFilters"
              aria-controls="offcanvasFilters"
              @click="openFilters"
            ><i data-feather="filter" /></a></span>

            <span
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Settings"
            ><a
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasTableSettings"
              aria-controls="offcanvasTableSettings"
              @click="$store.dispatch('modals/toggleTableSettings', true)"
            ><i data-feather="settings" /></a></span><!-- sólo admin -->
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <SearchResearcher :item="userSelected" />

            <PersonalInformation
              v-if="!loading"
              :user-id="userIdSelected"
            />

            <div class="viewform__actions--buttons mb-2">
              <a
                class="btn btn-sm btn-flat-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Display all blocks"
                @click="displaycards(true)"
              ><i data-feather="maximize-2" /> Display all</a>
              <a
                class="btn btn-sm btn-flat-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse all blocks"
                @click="displaycards(false)"
              ><i data-feather="minimize-2" /> Collapse all</a>
            </div>
            <template
              v-for="field in fields"
            >
              <Stats
                v-if="field.checked && field.name === 'Stats'"
                :key="field.id"
                :year-between="yearBetween"
                :current-year="currentYear"
                :user-id="userIdSelected"
                :show-all-stats="showAll"
                :toggled="toggled"
                :field="field"
              />

              <Publications
                v-if="field.checked && field.name === 'Publications'"
                :key="field.id"
                :user-id="userIdSelected"
                :toggled="toggled"
                :year-between="yearBetween"
                :field="field"
                @goToIndex="goToIndex"
              />

              <GrantsResource
                v-if="field.checked && field.name === 'Grants & resources'"
                :key="field.id"
                :user-id="userIdSelected"
                :toggled="toggled"
                :year-between="yearBetween"
                :field="field"
                @goToIndex="goToIndex"
              />

              <Conferences
                v-if="field.checked && field.name === 'Conferences, workshops and invited talks'"
                :key="field.id"
                :user-id="userIdSelected"
                :toggled="toggled"
                :year-between="yearBetween"
                :field="field"
                @goToIndex="goToIndex"
              />

              <CoursesAndSeminars
                v-if="field.checked && field.name === 'Courses and seminars'"
                :key="field.id"
                :user-id="userIdSelected"
                :toggled="toggled"
                :year-between="yearBetween"
                :field="field"
                @goToIndex="goToIndex"
              />

              <PhDTheses
                v-if="field.checked && field.name === 'PhD, master theses and TFG'"
                :key="field.id"
                :user-id="userIdSelected"
                :toggled="toggled"
                :year-between="yearBetween"
                :field="field"
                @goToIndex="goToIndex"
              />

              <ManagerialActivities
                v-if="field.checked && field.name === 'Managerial activities'"
                :key="field.id"
                :user-id="userIdSelected"
                :toggled="toggled"
                :year-between="yearBetween"
                :field="field"
                @goToIndex="goToIndex"
              />

              <Dissemination
                v-if="showAll && field.checked && field.name === 'Dissemination'"
                :key="field.id"
                :user-id="userIdSelected"
                :toggled="toggled"
                :year-between="yearBetween"
                :field="field"
                @goToIndex="goToIndex"
              />

              <StayResearch
                v-if="showAll && field.checked && field.name === 'Stays of research'"
                :key="field.id"
                :user-id="userIdSelected"
                :toggled="toggled"
                :year-between="yearBetween"
                :field="field"
                @goToIndex="goToIndex"
              />

              <Patents
                v-if="showAll && field.checked && field.name === 'Technology transfer'"
                :key="field.id"
                :user-id="userIdSelected"
                :toggled="toggled"
                :year-between="yearBetween"
                :field="field"
                @goToIndex="goToIndex"
              />

              <Sabbaticals
                v-if="showAll && field.checked && field.name === 'Sabbaticals'"
                :key="field.id"
                :user-id="userIdSelected"
                :toggled="toggled"
                :year-between="yearBetween"
                :field="field"
                @goToIndex="goToIndex"
              />
            </template>
          </div>
        </div>

      </div>
    </div>
    <TableColumns />
    <ItemInfoSheet :table-id="filterInfoSheet">
      <template #info-sheet-title>
        Search & Filters
      </template>
      <template
        #info-sheet
      >
        <div class="offcanvas-body">
          <div class="mb-1 form-group">
            <label
              for=""
              class="form-label"
            >Begin year</label>
            <date-picker
              v-model="filters['from']"
              format="Y"
              type="year"
              value-type="format"
              class="w-100"
            />
          </div>
          <div class="mb-1 form-group">
            <label
              for=""
              class="form-label"
            >End year</label>
            <date-picker
              v-model="filters['to']"
              format="Y"
              type="year"
              value-type="format"
              class="w-100"
            />
          </div>
        </div>
        <div class="offcanvas-footer mt-auto">
          <button
            type="button"
            class="btn btn-dark mb-1 d-grid w-100"
            @click="applyFilters"
          >
            Apply
          </button>
        </div>
      </template>
    </ItemInfoSheet>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import SearchResearcher from '../../partials/components/SearchResearcher.vue'
import Stats from './components/Stats.vue'
import PersonalInformation from './components/PersonalInformation.vue'
import Publications from './components/Publications.vue'
import GrantsResource from './components/GrantsResource.vue'
import Conferences from './components/Conferences.vue'
import CoursesAndSeminars from './components/CoursesAndSeminars.vue'
import PhDTheses from './components/PhDTheses.vue'
import ManagerialActivities from './components/ManagerialActivities.vue'
import Dissemination from './components/Dissemination.vue'
import StayResearch from './components/StayResearch.vue'
import Patents from './components/Patents.vue'
import Sabbaticals from './components/Sabbaticals.vue'
import TableColumns from '../../partials/offcanvas/TableColumns.vue'

export default {
  components: {
    SearchResearcher,
    Stats,
    PersonalInformation,
    Publications,
    GrantsResource,
    Conferences,
    CoursesAndSeminars,
    PhDTheses,
    ManagerialActivities,
    Dissemination,
    StayResearch,
    Patents,
    Sabbaticals,
    ItemInfoSheet,
    DatePicker,
    TableColumns,
  },
  data() {
    return {
      userSelected: {},
      loading: true,
      toggled: true,
      yearBetween: {},
      filters: [],
      filterInfoSheet: 1,
      currentYear: 0,
      showAll: true,
      defaultFields: [
        {
          name: 'Stats',
          checked: true,
          order: 1,
        },
        {
          name: 'Publications',
          checked: true,
          order: 2,
        },
        {
          name: 'Grants & resources',
          checked: true,
          order: 3,
        },
        {
          name: 'Conferences, workshops and invited talks',
          checked: true,
          order: 4,
        },
        {
          name: 'Courses and seminars',
          checked: true,
          order: 5,
        },
        {
          name: 'PhD, master theses and TFG',
          checked: true,
          order: 6,
        },
        {
          name: 'Managerial activities',
          checked: true,
          order: 7,
        },
        {
          name: 'Dissemination',
          checked: true,
          order: 8,
        },
        {
          name: 'Stays of research',
          checked: true,
          order: 9,
        },
        {
          name: 'Technology transfer',
          checked: true,
          order: 10,
        },
        {
          name: 'Sabbaticals',
          checked: true,
          order: 11,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      fields: 'modals/tableSettingsFields',
    }),
    userIdSelected() {
      let aux = 0
      if (this.userSelected.user) {
        aux = this.userSelected.user.id
      } else if (this.$route.query.prefilterUser && this.loggedUser.roles.includes('super-admin')) {
        aux = this.$route.query.prefilterUser
      } else {
        aux = this.loggedUser.id
      }
      return Number.parseInt(aux, 10)
    },
  },
  async mounted() {
    this.currentYear = new Date().getFullYear()
    if (this.$route.query) {
      this.yearBetween = { ...this.$route.query }
      this.changeCurrentYearText(this.$route.query)
    }
    await this.$store.dispatch('modals/fetchUserFields', 'scientific_production_report_researcher')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'scientific_production_report_researcher',
      })
    }
    this.loading = false
  },
  methods: {
    applyFilters() {
      this.yearBetween = { ...this.filters }
      this.changeCurrentYearText(this.filters)

      this.$store.dispatch('modals/toggleInfoItemSheet', false)
    },
    changeCurrentYearText(filter) {
      if (filter.from && filter.to) {
        this.currentYear = `${filter.from} - ${filter.to}`
      } else if (filter.from) {
        this.currentYear = `From ${filter.from}`
      } else if (filter.to) {
        this.currentYear = `To ${filter.to}`
      } else {
        this.currentYear = new Date().getFullYear()
      }
    },
    openFilters() {
      this.$store.dispatch('modals/toggleTableClicked', this.filterInfoSheet)
      this.$store.dispatch('modals/toggleInfoItemSheet', true)

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    displaycards(item) {
      if (item) {
        this.toggled = true
      } else {
        this.toggled = false
      }
      this.$store.dispatch('preview/togglePersonalInformation', this.toggled)
    },
    async goToIndex(route) {
      let url = ''
      if (Array.isArray(route)) {
        if (route[0] && route[0].id) {
          url = this.$router.resolve({ name: route[1], params: route[0] }).href
        } else {
          url = this.$router.resolve({ name: route[1], query: route[0] }).href
        }
      }
      window.open(url, '_blank')
    },
  },
}
</script>
